import { createMuiTheme } from '@material-ui/core/styles';

export let HDMuiTheme = createMuiTheme({
    palette: {
        primary: {
          main: "#f96302",
          dark: "#c14c00",
          light: "#f96302"
        },
      },
});