import React from 'react'
import { Grid, Box,Typography, Switch } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const UpgradeItem = ({quote, handleQuoteChange, upgrade, index}) => {
    // console.log(quote)
 
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })

      const dollarize = string => {
        if (parseInt(string)){
            return formatter.format(Math.abs(string) / .85)
        }else{
        return string 
        }
    }

    // const getSelectValue = textKey => {
    //     return (textKey === "shutters" ? quote.shutterCount : quote.windowCount)
    // }

    const checkDisabled = (textKey, valueKey) => {
        
        switch(true){
            case (textKey === "dimensionalShingles" && parseFloat(quote.highWind) > 0):
                return false
            case (textKey === "dimensionalShingles" && quote.highWind < 0):
                return true        
            case (textKey === "highWind"):
                return false
            case (textKey === "gableVents"):
                return false
            case (textKey === "highSnow"):
                return false
            case (textKey === "extraDoor"):
                return false
            case (textKey === "blackHardware"):
                return false
            case (textKey === "insulatedGarageDoor"):
                return false
            case (textKey === "garageDoorWindow"):
                return false
            case (textKey === "shutters"):
                    return false
            case (textKey === "fiveTwelveRoof" && (quote.roofPitch === 4 || quote.roofPitch === 5)):
                return false 
            case (textKey === "sixTwelveRoof" && (quote.roofPitch === 4 || quote.roofPitch === 6)):
                return false 
            case (textKey === "scissorTruss" && quote.roofPitch !== 4 && parseInt(quote.atticTruss) > 0):
                return false 
            case (textKey === "atticTruss" && quote.roofPitch !== 4 && parseInt(quote.scissorTruss) > 0):
                    return false
            case (textKey === "generatorRental"):
                return false                                                
            // case (quote.region != "E" && textKey === "dimensionalShingles"):
            //     return false
            // case (textKey === "dimensionalShinglesFiveTwelve" && quote.roofPitch === 5):
            //         return false
            // case (textKey === "dimensionalShinglesSixTwelve" && quote.roofPitch === 6):
            //         return false
            default:
                return true
        }
    }

    const checkPrice = (price, item) => {
        if (item === "shutters"){
            return dollarize((price *(parseInt(quote.windowCount))))
        } else {
            return dollarize(price)
        }
    }

    let text = upgrade[1]
    let textKey = upgrade[0]
    let valueKey = quote[textKey]



        return (
            <Box 
            bgcolor={ (index % 2 === 0)
                ? "primary.lightGrey" 
                : null
            }
        >

        { index !== 1 ? 

            (
           
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={8}>
                    <Typography>
                        {text}
                    </Typography>
                    </Grid>
                        <Grid item xs={4}>        
                            <FormControlLabel
                                label={checkPrice(valueKey, textKey)}
                                    control={<Switch 
                                        name="switch" 
                                        checked={valueKey >= 0 ? false : true}
                                        value={textKey} 
                                        color="primary" 
                                        onChange={handleQuoteChange}
                                        disabled={checkDisabled(textKey, valueKey)}
                                    />} 
                            />
                        </Grid>
                    </Grid>
            )

            :
            
            (
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={8}>
                        <Typography >
                            {text}
                        </Typography>
                    </Grid>
                        <Grid item xs={4}>      
                            <FormControl>
                                    <Select
                                    native
                                    name='select'
                                    value={quote.windowCount}
                                    onChange={handleQuoteChange}
                                    inputProps={{
                                        name: 'select',
                                        id: `${textKey}`
                                    }}
                                    >
                                        <option value='0'>{`0 -- $0.00`}</option>
                                        <option value='1'>{`1 -- $0.00`}</option>
                                        <option value='2'>{`2 -- $0.00`}</option>
                                        <option value='3'>{`3 -- ${dollarize(valueKey*1)}`}</option>
                                        <option value='4'>{`4 -- ${dollarize(valueKey*2)}`}</option>
                                        <option value='5'>{`5 -- ${dollarize(valueKey*3)}`}</option>

                                    </Select>
                            </FormControl>
                    </Grid>
                </Grid>
            )


        }
        </Box>

        )
    }









export default UpgradeItem