import React, { useState } from "react";
import { SHED_DATA, INITIAL_STATE } from "./shedData";
import "date-fns";
import { pdf } from "@react-pdf/renderer";
import OrderFormPdf from "../theme/OrderFormPDF";
import QuotePDF from "../theme/QuotePDF";

// https://clayton-mail-proxy.azurewebsites.net/

// This file contains all logic related to state mutation
const UseForm = (callback) => {
    const [quote, setQuote] = useState(INITIAL_STATE);
    const [form, setForm] = useState({
        show: false,
        generateQuote: false,
        emailResponse: false,
        orderFormResponse: false,
        sendOrderModal: false,
        clickSubmit: false,
    });
    const [errors, setErrors] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const sendOrderForm = async () => {
        const blob = await pdf(
            <OrderFormPdf
                fileName={`TS-Clayton-${quote.firstName}-${quote.lastName}-${quote.requestNumber}`}
                quote={quote}
                form={form}
            />
        ).toBlob();
        const reader = new FileReader();
        reader.readAsBinaryString(blob);

        reader.onload = async function () {
            const blob64 = btoa(reader.result);
            const response = await fetch(
                "https://clayton-mail-proxy.azurewebsites.net/send-order-form",
                {
                    method: "POST",
                    body: JSON.stringify({
                        blob: blob64,
                        repEmail: quote.repEmail,
                        subjectLine: `Tuff Shed-Clayton Order: ${quote.firstName} ${quote.lastName} #${quote.requestNumber}`,
                        fileName: `TS-Clayton-${quote.firstName}-${quote.lastName}-${quote.requestNumber}.pdf`,
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );

            const json = await response.json();
            json.response === "SUCCESS!"
                ? setForm(
                      (form) => ({ ...form, orderFormResponse: true }),
                      console.log(json.response)
                  )
                : setErrors(
                      (errors) => ({ ...errors, orderForm: json.response }),
                      console.log(json.response, errors)
                  );
        };
    };

    const sendEmail = async () => {
        const blob3 = await pdf(
            <QuotePDF
                fileName={`TS-Clayton-${quote.firstName}-${quote.lastName}-${quote.requestNumber}`}
                quote={quote}
                form={form}
            />
        ).toBlob();
        const reader = new FileReader();
        reader.readAsBinaryString(blob3);
        reader.onload = async function () {
            const blob364 = btoa(reader.result);
            const response = await fetch(
                "https://clayton-mail-proxy.azurewebsites.net/send-email",
                {
                    method: "POST",
                    body: JSON.stringify({
                        blob3: blob364,
                        repEmail: quote.repEmail,
                        fileName3: `TS-Clayton ${quote.firstName} ${quote.lastName} ${quote.requestNumber}.pdf`,
                        subjectLine: `Tuff Shed-Clayton Quote Summary: ${quote.firstName} ${quote.lastName} #${quote.requestNumber}`,
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );

            const json = await response.json();
            json.response === "SUCCESS!"
                ? setForm(
                      (form) => ({ ...form, emailResponse: true }),
                      console.log(json.response)
                  )
                : setErrors(
                      (errors) => ({ ...errors, email: json.response }),
                      console.log(json.response, errors)
                  );
        };
    };

    const handleGenerateQuote = (event) => {
        setForm((form) => ({ ...form, generateQuote: !form.generateQuote }));
        if (quote.requestNumber.length <= 0) {
            setQuote((quote) => ({ ...quote, requestNumber: getHash() }));
        }
        // console.log(form)
    };

    const handleClickSubmit = async (event) => {
        setForm((form) => ({ ...form, clickSubmit: !form.clickSubmit }));
        await Promise.all([sendEmail(), sendOrderForm()]);
        // setForm(form => ({ ...form, clickSubmit: !form.clickSubmit}))
        // await Promise.all([sendEmail(), sendOrderForm()])
        // .then(errors.length > 0 ? setForm(form => ({ ...form, clickSubmit: false})) : null)
        // .then(console.log(form, "Clicked Submit", errors))
    };

    const handleEmailResponse = (event) => {
        setForm((form) => ({ ...form, emailResponse: !form.emailResponse }));
        // console.log(form)
    };

    const handleSendOrderModal = (event) => {
        setForm((form) => ({ ...form, sendOrderModal: !form.sendOrderModal }));
        if (quote.requestNumber.length <= 0) {
            setQuote((quote) => ({ ...quote, requestNumber: getHash() }));
        }

        // console.log(form)
    };

    const getHash = () => {
        return (+new Date() + Math.floor(Math.random() * Math.floor(100)))
            .toString(36)
            .toUpperCase();
    };

    const handleFormChange = (event) => {
        setForm((form) => ({ ...form, show: !form.show }));
    };

    const handleSize = (event) => {
        const { name, value } = event.target;
        if (quote.region !== "") {
            let currentModel = SHED_DATA.filter(
                (shed) => shed.region === quote.region && shed.size === value
            );
            let modelObject = currentModel[0];
            Object.assign(quote, modelObject);
        } else {
            setQuote((quote) => ({ ...quote, [name]: value }));
        }
    };

    const handleRegion = async (event) => {
        const { name, value } = event.target;
        if (value.length === 5) {
            //Hit market lookup endpoint
            const response = await fetch(
                `https://ts-marketlookup-prd-usnc-func.azurewebsites.net/api/marketInfo/${value}?code=6SPap9OE9H/7UxIxh5o5/D0s2iIgQ19cpUMGdINkZ8qbLX5iJBQ0kQ==`,
                {
                    method: "GET",
                }
            );
            const json = await response.json();

            if (quote.size !== "") {
                //Update selected shed (if any) to new region
                let currentModel = SHED_DATA.filter(
                    (shed) =>
                        shed.size === quote.size &&
                        shed.region === json.RetailTierLetter
                );
                let modelObject = currentModel[0];
                Object.assign(quote, modelObject);
            } else {
                //Otherwise, just set the region
                setQuote((quote) => ({
                    ...quote,
                    [name]: json.RetailTierLetter,
                }));
            }
        }
    };

    const getUpgradesTotal = (value) => {
        let upgradesTotalFloat = parseFloat(quote.upgradesTotal);
        let quoteUpgradeValue = parseFloat(quote[value]);
        let newUpgradesTotal = (upgradesTotalFloat += quoteUpgradeValue);
        return newUpgradesTotal;
    };
    const toggleSign = (num) => num * -1;

    const handleSelect = (event) => {
        const { value } = event.target;
        let newShuttersCount = value;
        if (parseInt(value) > 2) {
            let newWindowPrice =
                parseFloat(quote.insulatedWindow) * (value - 2);
            setQuote((quote) => ({
                ...quote,
                windowCount: value,
                windowsPrice: newWindowPrice,
                shuttersCount: newShuttersCount,
            }));
        } else {
            let newWindowPrice = 0;
            setQuote((quote) => ({
                ...quote,
                windowCount: value,
                windowsPrice: newWindowPrice,
                shuttersCount: newShuttersCount,
            }));
        }
    };

    const handleSwitch = (event) => {
        const { value } = event.target;
        const { claytonPrice, slabAllowance, roofPitch, electrical } = quote;
        const newUpgradesTotal = getUpgradesTotal(value);
        const newCustomerTotal =
            newUpgradesTotal +
            parseFloat(claytonPrice) +
            slabAllowance +
            electrical;
        if (value === "fiveTwelveRoof" && roofPitch !== 5) {
            setQuote((quote) => ({
                ...quote,
                upgradesTotal: newUpgradesTotal,
                customerTotal: newCustomerTotal,
                roofPitch: 5,
                [value]: toggleSign(quote[value]),
            }));
        } else if (value === "sixTwelveRoof" && roofPitch !== 6) {
            setQuote((quote) => ({
                ...quote,
                upgradesTotal: newUpgradesTotal,
                customerTotal: newCustomerTotal,
                roofPitch: 6,
                [value]: toggleSign(quote[value]),
            }));
        } else if (value === "sixTwelveRoof") {
            setQuote((quote) => ({
                ...quote,
                upgradesTotal: newUpgradesTotal,
                customerTotal: newCustomerTotal,
                roofPitch: 4,
                [value]: toggleSign(quote[value]),
            }));
        } else if (value === "fiveTwelveRoof") {
            setQuote((quote) => ({
                ...quote,
                upgradesTotal: newUpgradesTotal,
                customerTotal: newCustomerTotal,
                roofPitch: 4,
                [value]: toggleSign(quote[value]),
            }));
        } else if (value === "highWind") {
            let addShinglesPrice =
                newUpgradesTotal + parseFloat(quote.dimensionalShingles);
            let newCustomerTotalWithShingles =
                addShinglesPrice +
                parseFloat(claytonPrice) +
                slabAllowance +
                electrical;
            setQuote((quote) => ({
                ...quote,
                upgradesTotal: addShinglesPrice,
                customerTotal: newCustomerTotalWithShingles,
                dimensionalShingles: toggleSign(quote.dimensionalShingles),
                highWind: toggleSign(quote.highWind),
            }));
        } else if (value === "shutters") {
            let newShuttersPrice = quote.shuttersCount * 55;
            if (parseFloat(quote.shutters) < 0) {
                setQuote((quote) => ({
                    ...quote,
                    shuttersPrice: 0,
                    shutters: toggleSign(quote.shutters),
                }));
            } else {
                setQuote((quote) => ({
                    ...quote,
                    shuttersPrice: newShuttersPrice,
                    shutters: toggleSign(quote.shutters),
                }));
            }
        } else {
            setQuote((quote) => ({
                ...quote,
                upgradesTotal: newUpgradesTotal,
                customerTotal: newCustomerTotal,
                [value]: toggleSign(quote[value]),
            }));
        }
    };

    const handleQuoteChange = (event) => {
        const { name } = event.target;
        event.persist();
        console.log(quote);
        switch (name) {
            case "region":
                if (event.target.value.length === 5) {
                    handleRegion(event);
                }
                break;
            case "size":
                handleSize(event);
                break;
            case "switch":
                handleSwitch(event);
                break;
            case "select":
                handleSelect(event);
                break;
            case "wall":
                setQuote((quote) => ({
                    ...quote,
                    [event.target.id]: event.target.value,
                }));
                break;
            default:
                setQuote((quote) => ({
                    ...quote,
                    [event.target.name]: event.target.value,
                }));
                break;
        }
        setQuote((quote) => ({
            ...quote,
            [event.target.name]: event.target.value,
        }));
    };

    return {
        handleSubmit,
        quote,
        handleQuoteChange,
        form,
        handleFormChange,
        handleGenerateQuote,
        sendEmail,
        handleEmailResponse,
        handleSendOrderModal,
        handleClickSubmit,
        errors,
    };
};

export default UseForm;
