import React from 'react';
import { Grid, Box, Typography, TextField, Divider } from '@material-ui/core';




const CustomerInfo = ({quote, handleQuoteChange }) => {
     new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })


    return (
            <Box p={3}>
                <Typography variant="h4" gutterBottom component="h1">Order Information</Typography>
                <Divider></Divider>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                // required
                                id="claytonPo"
                                name="claytonPo"
                                label="Clayton PO Number"
                                onChange={handleQuoteChange}
                                value={quote.claytonPo}
                                fullWidth

                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                // required
                                id="claytonOrderNumber"
                                name="claytonOrderNumber"
                                label="Clayton Order Number"
                                onChange={handleQuoteChange}
                                value={quote.claytonOrderNumber}
                                fullWidth

                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                // required
                                id="homeCenterNumber"
                                name="homeCenterNumber"
                                label="Home Center Number"
                                fullWidth
                                autoComplete=""
                                onChange={handleQuoteChange}
                                value={quote.homeCenterNumber}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                // required
                                id="repFirstName"
                                name="repFirstName"
                                label="Home Consultant First Name"
                                fullWidth
                                autoComplete=""
                                onChange={handleQuoteChange}
                                value={quote.repFirstName}

                            />
                        </Grid><Grid item xs={12} sm={6}>
                            <TextField
                                // required
                                id="repLastName"
                                name="repLastName"
                                label="Home Consultant Last Name"
                                fullWidth
                                autoComplete=""
                                onChange={handleQuoteChange}
                                value={quote.repLastName}

                            />
                        </Grid>


                        <Grid item xs={6} >
                            <TextField
                                required
                                id="repEmail"
                                name="repEmail"
                                label="Home Consultant Email Address"
                                fullWidth
                                autoComplete=""
                                onChange={handleQuoteChange}
                                value={quote.repEmail}

                            />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                // required
                                id="repPhone"
                                name="repPhone"
                                label="Home Consultant Phone Number"
                                fullWidth
                                autoComplete=""
                                onChange={handleQuoteChange}
                                value={quote.repPhone}

                            />
                        </Grid> 
                        <Grid item xs={6} >
                            <TextField
                                // required
                                id="installDate"
                                name="installDate"
                                label="Projected Install Date"
                                fullWidth
                                autoComplete=""
                                onChange={handleQuoteChange}
                                value={quote.installDate}

                            />
                        </Grid> 

                        <Grid item xs={12}>
                        <Box mb={6}>
                            <TextField
                                // required
                                id="notes"
                                name="notes"
                                label="notes"
                                fullWidth
                                autoComplete="notes"
                                onChange={handleQuoteChange}
                                value={quote.notes}
                            />
                            </Box>
                        </Grid>   
                        <Grid item xs={12}><Typography variant="h4" component="h1">Customer Information</Typography></Grid>
                        <Grid item xs={12}><Divider></Divider></Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="firstName"
                                name="firstName"
                                label="First name"
                                fullWidth
                                autoComplete="fname"
                                onChange={handleQuoteChange}
                                value={quote.firstName}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="lastName"
                                name="lastName"
                                label="Last name"
                                fullWidth
                                autoComplete="lname"
                                onChange={handleQuoteChange}
                                value={quote.lastName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                // required
                                id="email"
                                name="email"
                                label="Email"
                                fullWidth
                                autoComplete=""
                                onChange={handleQuoteChange}
                                value={quote.email}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                // required
                                id="phone"
                                name="phone"
                                label="Phone Number"
                                fullWidth
                                autoComplete=""
                                onChange={handleQuoteChange}
                                value={quote.phone}
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <TextField
                                // required
                                id="address1"
                                name="address1"
                                label="Address line 1"
                                fullWidth
                                autoComplete="billing address-line1"
                                onChange={handleQuoteChange}
                                value={quote.address1}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="address2"
                                name="address2"
                                label="Address line 2"
                                fullWidth
                                autoComplete="billing address-line2"
                                onChange={handleQuoteChange}
                                value={quote.address2}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                // required
                                id="city"
                                name="city"
                                label="City"
                                fullWidth
                                autoComplete="billing address-level2"
                                onChange={handleQuoteChange}
                                value={quote.city}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField id="state"
                                name="state"
                                label="State/Province/Region"
                                fullWidth
                                onChange={handleQuoteChange}
                                value={quote.state}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                // required
                                id="zip"
                                name="zip"
                                label="Zip / Postal code"
                                fullWidth
                                autoComplete="billing postal-code"
                                onChange={handleQuoteChange}
                                value={quote.zip}
                            />
                        </Grid>

                     
                            <Grid item xs={3}  >
                            <TextField
                                // required
                                id="date"
                                name="date"
                                label="Date"
                                fullWidth
                                autoComplete=""
                                onChange={handleQuoteChange}
                                value={quote.date}

                            />
                            </Grid>

                </Grid>
            </Box>
        

    )
}

export default CustomerInfo
