import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Route, Switch } from "react-router-dom";
import ClaytonPortal from './components/clayton/ClaytonPortal';
import DealerPortal from './components/dealer/DealerPortal';
import Home from './components/Home';
import HDPortal from './components/homedepot/HDPortal';
import './global.scss';
import { TSMuiTheme } from './TSMuiTheme';

export default function App() {
    return (
        <div id="App">
            <CssBaseline />
            <ThemeProvider theme={TSMuiTheme}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/clayton" component={ClaytonPortal} />
                    <Route path="/dealer" component={DealerPortal} />
                    <Route path="/homedepot" component={HDPortal} />
                </Switch>
            </ThemeProvider>
        </div>
    );
}