import React from "react";
// import { makeStyles } from '@material-ui/core/styles';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

const RegionSelect = ({ quote, handleQuoteChange }) => {
    return (
        <FormControl style={{ minWidth: 100 }}>
            <TextField
                onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    e.target.value = e.target.value.slice(0, 5);
                }}
                id="region"
                name="region"
                label={quote.region.match(/^[0-9]*$/) ? "Delivery Zip" : "Tier"}
                fullWidth
                autoComplete=""
                disabled={quote.region.match(/^[A-Z]/)}
                onChange={handleQuoteChange}
                value={quote.region}
            ></TextField>
        </FormControl>
    );
};

export default RegionSelect;
