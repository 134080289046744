import React from 'react';
import { Grid, CardActionArea, Card, CardMedia, CardContent, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      marginTop: 30,
    },
    media: {
      height: 180,
    },
  });

export default function ClaytonHome(props) {
    let componentRoot = props.match.path.replace(/\/$/, "");
    const classes = useStyles();

    return <div>
        <Container maxWidth='lg' className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs></Grid>
                <Grid item xs>
                    <Card>
                        <CardActionArea onClick={() => props.history.push(`${componentRoot}/configurator`)}>
                            <CardMedia
                                className={classes.media}
                                image="/img/build.jpg"
                                title="Build a Garage"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Build a Garage
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Configure a Garage for a customer
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs></Grid>
            </Grid>
        </Container>
    </div>;
}