import { createMuiTheme } from '@material-ui/core/styles';

export let TSMuiTheme = createMuiTheme({
    palette: {
        primary: {
          main: "#981b1e",
          dark: "#981b1e",
          light: "#e2211c"
        },
      },
});