import { createMuiTheme } from '@material-ui/core/styles';

export let ClaytonMuiTheme = createMuiTheme({
    palette: {
        primary: {
          main: "#0075c9",
          dark: "#003f8c",
          light: "#e2211c",
          lightGrey: "#f5f5f5"
        },
        greyPrime: {
          main: "grey",
          dark: "#003f8c",
          light: "#e2211c",
          lightGrey: "#f5f5f5"
        },
      },
});