import React from 'react';
import { Text, View, StyleSheet} from '@react-pdf/renderer';

import { MODEL_UPGRADES_TEXT } from './shedData'



const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })
  
  const markup = string => dollarize(Math.abs(string)/ .85)
  
  const dollarize = string => {
    if (parseFloat(string)){
        return formatter.format(string)
    }else{
        return string 
    }
  }



const QuotePdfTable = ({quote, quoteData}) =>{
  
  const initialRows = [

    {item: quote.modelName, quantity: quote.qty , price: `${dollarize(quote.claytonPrice / .85)}` || 0},
    {item: 'Windows', quantity: (quote.modelName === "**Select Garage Model**" ? "-" : quote.windowCount ), price: `${dollarize(quote.windowsPrice / .85)}` || 0},
    {item: `Siding Color - ${quote.sidingColor}`, quantity: "-", price: "0"},
    {item: `Trim Color - ${quote.trimColor}`, quantity: "-", price: "0"},
    {item: `Shingle Color - ${quote.shingleColor}`, quantity: "-", price: "0"},
    {item: 'Garage Total', quantity:"", price: `${dollarize( ((quote.customerTotal / .85) + (quote.windowsPrice / .85)+ (quote.shuttersPrice / .85)) ) }` || 0},
    {item: 'Concrete Pad', quantity: "", price: `${dollarize(quote.slabAllowance)}`|| 0},
    { item: 'Electrical', quantity: "", price: `${dollarize(quote.electrical)}` || 0 },

    {item: 'Grand Total', quantity:"", price: `${
          dollarize(
            (
                (quote.customerTotal / .85) +
                parseFloat(quote.slabAllowance || 0) +
                parseFloat(quote.electrical || 0) +
                (quote.windowsPrice / .85) +
                (quote.shuttersPrice / .85)
            )
          )
        }` 
        || 0
  }
  ];

    const styles = StyleSheet.create({
        body: {
          padding: 10,
        },
        table: { 
        //   display: "table", 
          width: "100%",
          // marginLeft: 'auto',
          // alignContent: 'center',
          // justifyContent: 'center', 
      
        }, 
        tableRow: { 
          margin: "auto", 
          flexDirection: "row",

        },
        itemHeader: {
          width: "70%",

        },
        itemCol: {
          width: "70%",

        },  
        tableColHeader: { 
          width: "15%", 
      
        },   
        tableCol: { 
          width: "15%", 
    
        }, 
        tableCellHeader: {
          // margin: "auto", 
          margin: 5, 
          fontSize: 12,
          fontWeight: 500
        },  
        tableCell: { 
          // margin: "auto", 
          margin: 5, 
          fontSize: 10 
        },
        container: {
           alignContent: 'center',
           justifyContent: 'center'
        }
      });

      const checkQuantity = upgrade => {
        if (upgrade[0] === 'shutters') {
          return parseInt(quote.windowCount)
        } else if (upgrade[0] === 'insulatedWindow'){
          return (parseInt(quote.windowCount))
        } else {
          return 1
        }
      }

      const checkPrice = upgrade => {
        if (upgrade[0] === 'shutters') {
          return `${markup(upgrade[1] * quote.windowCount)}`
        } else {
          return `${markup(Math.abs(upgrade[1]))}`
        }
      }

      const matchText = string => {
        const upgradesAsList = Object.entries(MODEL_UPGRADES_TEXT)
        const matchItemDescription = upgradesAsList.filter(item => (item[0] === string))
        const itemDescription = matchItemDescription[0][1]
        return itemDescription
      }

      const getRows = () => {
        let rows = initialRows
        const quoteAsList = Object.entries(quote)
        const addedUpgrades = quoteAsList.filter(item => (typeof (item[1]) === "number" && item[1] < 0 ))
        if (addedUpgrades.length > 0){
          addedUpgrades.map(upgrade => {
              let formattedUpgrade =
                {item: `${matchText(upgrade[0])}`, quantity: checkQuantity(upgrade), price: checkPrice(upgrade)} 
                rows.splice(-4, 0, formattedUpgrade)   
              })
          }


              return rows.map((row, index) =>( 
                <View style={[styles.tableRow, ( index % 2 === 0 ? {backgroundColor: '#f5f5f5'} : {backgroundColor: 'white'}) ]} key={index}> 
                          <View style={styles.itemCol}> 
                          <Text style={styles.tableCell}>{row.item}</Text> 
                          </View> 
                          <View style={styles.tableCol}> 
                          <Text style={styles.tableCell}>{row.quantity}</Text> 
                          </View> 
                          <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{row.price}</Text> 
                          </View>
                    </View> 
                
              ))
        
      }
      

        return (
      
          <View style={styles.container}>
            <View style={styles.table}> 
              <View style={styles.tableRow}> 
                <View style={styles.itemHeader}> 
                  <Text style={styles.tableCellHeader}>Item</Text> 
                </View> 
                <View style={styles.tableColHeader}> 
                  <Text style={styles.tableCellHeader}>Qty</Text> 
                </View> 
                <View style={styles.tableColHeader}> 
                  <Text style={styles.tableCellHeader}>Price</Text> 
                </View> 
              </View>
                {getRows()}

            </View>
          </View>
   
      );
}

export default QuotePdfTable