import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';
import OrderFormPdfTable from './OrderFormPdfTable'
// import { MODEL_FEATURES_TEXT } from './shedData'
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    marginTop: 5,
    paddingTop: 5,
    paddingBottom: 5,
    paddingHorizontal: 10,
    fontFamily: 'Helvetica'
  },

  header: {
    flexDirection: 'row',
    marginTop: 5,
    marginBottom: 5,
    height: 45,
    borderBottom: 1,
    borderBottomColor: 'grey',

  },
  tsLogo: {
      width: 150,
      height: 30,
      marginBottom: 5,
      paddingRight: 10,
      borderRight: 1,
      borderRightColor: 'grey'

  },
  claytonLogo: {
    width: 80,
    height: 29,
    marginLeft:5,
    marginBottom: 5
},
warning: {
  marginTop: 7,
  marginLeft: 5,
  color: 'red',
  fontSize: 20,
  fontFamily: 'Helvetica-Bold'

},
 numberAndDate: {
     width: '100%',
     fontSize: 8,
     fontWeight: 'bold',
     flexDirection: 'row',
     justifyContent: 'space-between',
     fontFamily: 'Helvetica-Bold'
 },
 InfoSection: {
  marginTop: 10,
  marginBottom: 10,
  paddingLeft: 10,
  flexDirection: 'row',
},
 orderInformation: {
    width: '33%',
    fontSize: 10,
    flexDirection: 'column',
    lineHeight: 1.2,
 },
 customerInformation: {
    width: '33%',
    fontSize: 10,
    flexDirection: 'column',
    lineHeight: 1.2,
    marginTop: 0

 }, 
 wallInformation: {
  width: '33%',
  fontSize: 10,
  flexDirection: 'column',
  lineHeight: 1.2

},
 boldText: {
     fontWeight: 'bold',
     fontSize: 14,
     fontFamily: 'Helvetica-Bold'
 },
 list: {
     marginLeft: 5,
     marginTop: 5
 },
 tableContainer: {
    width: '85%',
    marginLeft: 30,
    marginRight: 40
 },

 footer: {
  position: 'absolute',
  fontSize: 10,
  bottom: 10,
  left: 0,
  right: 0,
  textAlign: 'left',
  marginLeft: 20,
  marginBottom: 10
 },
 text: {
  marginTop: 5, 
  fontSize: 8,
  textAlign: 'justify',
  fontFamily: 'Times-Italic'
},
consultantSignature: {
  position: 'absolute',
  bottom: 70,
  flexDirection: 'row',
  margin: 5,
  marginLeft:10
},
customerSignature: {
  position: 'absolute',
  bottom: 35,
  flexDirection: 'row',
  margin: 5,
  marginLeft: 10  
},
signatureText: {
  justifyContent: 'space-around',
  fontSize: 10,
  marginLeft: 10
},
signatureLine: {
  width: 120,
  borderBottom: 1,
  borderBottomColor: 'black'
},
featuresContainer: {
  marginTop: 10,
  marginBottom: 10,
  marginLeft: 10,
  padding: 10,
  flexDirection: 'row',
   width: '90%',
  paddingTop: 20
 },
standardFeatures: {
    width: '50%',
    fontSize: 10,
    flexDirection: 'column',
    lineHeight: 1.2,
    marginBottom: 20
}

});
const getDateTime = () => {
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date+' '+time;

    return dateTime
}



// Create Document Component
const OrderFormPdf = ({quote, quoteData, form}) => {

  // const numberOfFeatures = (quote.region === "HW" ? 11 : 12)
  // const standardFeatures =  (Object.entries(MODEL_FEATURES_TEXT).slice(12))
  // console.log(standardFeatures)
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
            <Image src="/logo-hor.png" style={styles.tsLogo} />
            <Image src="/img/clayton-logo.png" style={styles.claytonLogo}/>
            <Text style={styles.warning}>TUFF SHED INTERNAL USE ONLY</Text>
        </View>
        <View style={styles.numberAndDate}>
            <Text>Quote Request Number: {quote.requestNumber}</Text>
            <Text>Created {getDateTime()} </Text>
        </View>

        { form.show === false ?
           
            (
           null
            )

        :

          (
            <View>
              <View style={styles.InfoSection}>
                  <View style={styles.orderInformation}>
                        <Text style={styles.boldText}>Order Information</Text>
                        <View style={styles.list}>
                            <Text>Clayton PO Number: {quote.claytonPo}</Text>
                            <Text>Clayton Order Number: {quote.claytonOrderNumber}</Text>
                            <Text>Home Center Number: {quote.homeCenterNumber}</Text>
                            <Text>Home Consultant Name: {quote.repFirstName} {quote.repLastName}</Text>
                            <Text>Home Consultant Email: {quote.repEmail} </Text>
                            <Text>Home Consultant Phone: {quote.repPhone}</Text>
                            <Text>Projected Install Date: {quote.installDate}</Text>
                            <Text>Notes: {quote.notes}</Text>

                        </View>
                  </View>

                  <View style={styles.customerInformation}>
                      <Text style={styles.boldText}>Customer Information</Text>
                        <View style={styles.list}>
                            <Text>Customer Name: {quote.firstName} {quote.lastName}</Text>
                            <Text>Customer Phone Number: {quote.phone}</Text>
                            <Text>Customer Email: {quote.email}</Text>
                            <Text>Address Line 1: {quote.address1} </Text>
                            <Text>Address Line 2: {quote.address2}</Text>
                            <Text>City: {quote.city}</Text>
                            <Text>State: {quote.state}</Text>
                            <Text>Zip: {quote.zip}</Text>
                        </View>
                  </View>


                  <View style={styles.wallInformation}>
                      <Text style={styles.boldText}>Window/Door Placement </Text>
                        <View style={styles.list}>
                            <Text>{quote.wallInfo} </Text>
                        </View>
                  </View>
                
              </View>

              </View>
          )
        }
              <View style={styles.tableContainer}>
                <OrderFormPdfTable quote={quote} quoteData={quoteData}/>
              </View>
          <View style={styles.footer}>
            <Text> &copy; Tuff Shed Inc. | Page 1 of 1</Text>
          </View>
      </Page>
    </Document>
      
  


      

  )
};

export default OrderFormPdf

