import React from 'react'
import { MODEL_UPGRADES_TEXT } from './shedData'
import { Grid, Box,Typography } from '@material-ui/core';

import UpgradeItem from './UpgradeItem'

const ModelUpgrades = ({ quote, handleQuoteChange }) => {
    const upgrades = Object.entries(MODEL_UPGRADES_TEXT)
    
    const { size, region } = quote

    return (

        <Box p={3}>
            <Typography variant="h6" gutterBottom>Model Upgrades</Typography>
            <Grid container spacing={3}>
            
                {
                    (size === "" || region === "") ? 
                        (   
                            <Grid item>
                                <Typography
                                    variant="h6" 
                                    gutterBottom 
                                    component="h3" 
                                    color="secondary"
                                >
                                    *Select Region and Size*
                                </Typography>

                            </Grid>
                        ) 

                        :

                        (   
                            <Grid item xs={12}> 
                                {   
                                    upgrades.map((upgrade, index) => {
                                        return (<UpgradeItem 
                                            quote={quote}
                                            handleQuoteChange={handleQuoteChange}
                                            upgrade={upgrade}
                                            index={index}
                                            key={index}
                                        />)
                                    
                                    })
                                }    
                            </Grid>
                        ) 
                }         
            </Grid>
        </Box>
    )
}

export default ModelUpgrades

