import React from 'react';
import { Grid, Container, Paper, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// import Quote from '../theme/Quote'
import CustomerInfo from '../theme/CustomerInfo'
import ModelFeatures from '../theme/ModelFeatures'
import ModelUpgrades from '../theme/ModelUpgrades'
import TierInfo from '../theme/TierInfo'
import QuoteTable from '../theme/QuoteTable'
import WallInfo from '../theme/WallInfo'
import ColorSelector from '../theme/ColorSelector'
import SendOrderModal from '../theme/SendOrderModal'
import SaveQuoteModal from '../theme/SaveQuoteModal'


import  UseForm  from '../theme/UseForm'

const useStyles = makeStyles({
    root: {
        marginTop: 30,
    }
});

const ClaytonConfigurator = () => {
    const classes = useStyles();

    const {  
        handleSubmit, 
        quote,
        handleQuoteChange,
        form,
        handleFormChange,
        sendEmail,
        handleGenerateQuote,
        handleSendOrderModal,
        handleEmailResponse,
        handleClickSubmit,
        errors
        

    } = UseForm();
    
    return (
        <div>
        <SaveQuoteModal handleGenerateQuote={handleGenerateQuote} form={form} quote={quote}/> 
        <SendOrderModal 
            handleSendOrderModal={handleSendOrderModal} 
            form={form} quote={quote} 
            sendEmail={sendEmail} 
            handleEmailResponse={handleEmailResponse}
            handleClickSubmit={handleClickSubmit}
            errors={errors}   
            /> 

            <Container maxWidth='lg' className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item md={8}>
                        <form id="quoteForm" onSubmit={handleSubmit}>
                        <Paper>                
                            <TierInfo quote={quote}  method="post" handleQuoteChange={handleQuoteChange}/>

                            <ModelFeatures 
                                quote={quote}
                                handleQuoteChange={handleQuoteChange}
                            />
                            <ModelUpgrades
                                quote={quote}
                                handleQuoteChange={handleQuoteChange} 
                            />
                            <WallInfo
                                quote={quote}
                                handleQuoteChange={handleQuoteChange} 
                            />
                            <ColorSelector    
                                quote={quote}
                                handleQuoteChange={handleQuoteChange}

                            />
                        </Paper>
                        <Box display="flex" flexDirection='row' justifyContent="space-around" my={2}>
 
                                        <Button
                                            variant="contained"
                                            size="large"
                                            type="button"
                                            color={"primary"}                                            
                                            onClick={handleGenerateQuote}
                                        > 
                                            Generate Quote PDF
                                        </Button>

                                        <Button
                                            variant="contained"
                                            size="large"
                                            type="button"
                                            color={"default"}
                                            // disabled={ !form.show }
                                            onClick={handleFormChange}
                                        > Continue Order
                                        </Button>

                        </Box>
                        <Box mb={2}>
                            <Paper>
                                {form.show ? <CustomerInfo quote={quote} handleQuoteChange={handleQuoteChange} /> : null}
                            </Paper>
                        </Box>
          


                        <Box display={form.show ? "block" : "none"}>
                            <Button 
                                type="button" 
                                fullWidth variant="contained" 
                                color="primary"
                                size="large"
                                onClick={handleSendOrderModal}
                                >
                                Submit Order Form
                            </Button>
                        </Box> 
                        </form>
                    </Grid>
                    <Grid item md >
                    <Box width="25%" position='absolute' >
                        <QuoteTable quote={quote}/>
                    </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default ClaytonConfigurator

