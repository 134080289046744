import React from 'react';
import { Route, Switch } from "react-router-dom";
import DealerNav from './DealerNav';
import DealerHome from './pages/DealerHome';

export default function DealerPortal(props) {
    let componentRoot = props.match.path.replace(/\/$/, "");

    return <div id="DealerPortal">
        <DealerNav match={props.match} />
        <Switch>                
            <Route exact path={`${componentRoot}/`} component={DealerHome} />
        </Switch>
    </div>
}