import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';




const SizeSelect = ({ quote, handleQuoteChange }) => {
  return (
    <div>
      <FormControl>
        <InputLabel htmlFor="size">Size</InputLabel>
        <Select
          native
          name='size-select'
          value={quote.size}
          onChange={handleQuoteChange}
          inputProps={{
            name: 'size',
            id: 'size',
          }}
        >
          <option value="" />
          <option value="20">20' x 20'</option>
          <option value="22">22' x 22'</option>
          <option value="24">24' x 24'</option>
        </Select>
      </FormControl>
      
    </div>
  );
}

export default SizeSelect
