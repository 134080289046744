import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';
import QuotePdfTable from './QuotePdfTable'
import { MODEL_FEATURES_TEXT } from './shedData'
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    // marginTop: 5,
    margin: 15,
    // paddingTop: 5,
    // paddingBottom: 5,
    paddingHorizontal: 5,
    fontFamily: 'Helvetica'
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1
  // },
  // warning: {
  //   marginTop: 7,
  //   marginLeft: 5,
  //   color: 'red',
  //   fontSize: 12,
  //   fontFamily: 'Helvetica-Bold',
  //   width: 250,
  //   marginLeft: 20
  // },
  
  header: {
    width: '87%',
    flexDirection: 'row',
    marginLeft: 20,
    marginTop: 5,
    marginBottom: 5,
    height: 35,
    borderBottom: 1,
    borderBottomColor: 'grey',

  },
  tsLogo: {
      width: 150,
      height: 30,
      // marginBottom: 5,
      paddingRight: 10,
      borderRight: 1,
      borderRightColor: 'grey'

  },
  claytonLogo: {
    width: 80,
    height: 29,
    marginLeft:5,
    // marginBottom: 5
},
 numberAndDate: {
    marginLeft: 20,
     width: '87%',
     fontSize: 8,
     fontWeight: 'bold',
     flexDirection: 'row',
     justifyContent: 'space-between',
     fontFamily: 'Helvetica-Bold'
 },
 InfoSection: {
  // marginTop: -40,
  marginBottom: 10,
  paddingLeft: 10,
  flexDirection: 'row',
},
 orderInformation: {
  // marginTop: -40,

    width: '33%',
    fontSize: 10,
    flexDirection: 'column',
    lineHeight: 1.2,


 },
 customerInformation: {
    width: '33%',
    fontSize: 10,
    flexDirection: 'column',
    lineHeight: 1.2,
    // marginTop: 0

 }, 
 wallInformation: {
  // marginTop: -40,

  width: '33%',
  fontSize: 10,
  flexDirection: 'column',
  lineHeight: 1.2

},
 boldText: {
     fontWeight: 'bold',
     fontSize: 14,
     fontFamily: 'Helvetica-Bold'
 },
 list: {
     marginLeft: 5,
     marginTop: 5
 },
 tableContainer: {
    marginTop: -10,
    width: '85%',
    marginLeft: 30,
    marginRight: 40
 },

 disclaimer:{
  position: 'absolute',
  bottom: 60,
  fontSize: 10,
  textAlign: 'left',
  marginLeft: 30,
  marginRight: 20,
  padding: 0,
  marginBottom: 0,
  marginTop:0 
 },
 footer: {
  position: 'absolute',
  fontSize: 10,
  bottom: 30,
  left: 0,
  right: 0,
  textAlign: 'left',
  marginLeft: 30,
  marginBottom: 10
 },
 text: {
  marginTop: 5, 
  fontSize: 8,
  textAlign: 'justify',
  fontFamily: 'Times-Italic'
},
consultantSignature: {
  position: 'absolute',
  bottom: 85,
  flexDirection: 'row',
  margin: 5,
  marginLeft:20

},
customerSignature: {
  position: 'absolute',
  bottom: 55,
  flexDirection: 'row',
  margin: 5,
  marginLeft: 20
},
signatureText: {
  justifyContent: 'space-around',
  fontSize: 10,
  marginLeft: 10
},
signatureLine: {
  width: 120,
  borderBottom: 1,
  borderBottomColor: 'black'
},
featuresContainer: {
  marginTop: 10,
  marginBottom: 10,
  marginLeft: 10,
  padding: 10,
  flexDirection: 'row',
   width: '90%',
  paddingTop: 0
 },
standardFeatures: {
    width: '50%',
    fontSize: 10,
    flexDirection: 'column',
    lineHeight: 1.2,
    marginBottom: 20

 
}

});
const getDateTime = () => {
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date+' '+time;

    return dateTime
}



const QuotePDF = ({quote, quoteData, form}) => {

  const standardFeatures =  (Object.entries(MODEL_FEATURES_TEXT).slice(12))
  return (
    <Document>
      <Page size="Letter" style={styles.page}>
        <View style={styles.header}>
            <Image src="/logo-hor.png" style={styles.tsLogo} />
            <Image src="/img/clayton-logo.png" style={styles.claytonLogo}/>
        </View>
        <View style={styles.numberAndDate}>
            <Text>Quote Request Number: {quote.requestNumber}</Text>
            <Text>Created {getDateTime()} </Text>
        </View>

        { form.show === false ?
           
            (
              <View style={styles.featuresContainer}>
                <View style={styles.standardFeatures}>
                <Text style={styles.boldText}>Included Standard Features</Text>
                  <View style={styles.list}>
                  {  
                    standardFeatures.map((feature, index) => {
                      let text = feature[1] 
                        return (
                          <Text key={index}> {text} </Text>

                        )

                    })
                  }
      
                  </View>
                </View>

                <View style={styles.standardFeatures}>
                      <Text style={styles.boldText}>Window/Door Placement </Text>
                        <View style={styles.list}>
                            <Text>{quote.wallInfo} </Text>

           
                        </View>
                  </View>

              </View>
            )

        :

          (
            <View>
              <View style={styles.InfoSection}>
                  <View style={styles.orderInformation}>
                        <Text style={styles.boldText}>Order Information</Text>
                        <View style={styles.list}>
                            <Text>Clayton PO Number: {quote.claytonPo}</Text>
                            <Text>Clayton Order Number: {quote.claytonOrderNumber}</Text>
                            <Text>Home Center Number: {quote.homeCenterNumber}</Text>
                            <Text>Home Consultant Name: {quote.repFirstName} {quote.repLastName}</Text>
                            <Text>Home Consultant Email: </Text>
                            <Text>{quote.repEmail}</Text>
                            <Text>Home Consultant Phone: {quote.repPhone}</Text>
                            <Text>Projected Install Date: {quote.installDate}</Text>
                            <Text>Notes: {quote.notes}</Text>

                        </View>
                  </View>

                  <View style={styles.customerInformation}>
                      <Text style={styles.boldText}>Customer Information</Text>
                        <View style={styles.list}>
                            <Text>Customer Name: {quote.firstName} {quote.lastName}</Text>
                            <Text>Customer Phone Number: {quote.phone}</Text>
                            <Text>Customer Email: {quote.email}</Text>
                            <Text>Address Line 1: {quote.address1} </Text>
                            <Text>Address Line 2: {quote.address2}</Text>
                            <Text>City: {quote.city}</Text>
                            <Text>State: {quote.state}</Text>
                            <Text>Zip: {quote.zip}</Text>
                        </View>
                  </View>


                  <View style={styles.wallInformation}>
                      <Text style={styles.boldText}>Window/Door Placement </Text>
                        <View style={styles.list}>
                            <Text>{quote.wallInfo} </Text>
                        </View>
                  </View>
                
              </View>

              </View>
          )
        }
              <View style={styles.tableContainer}>
                <QuotePdfTable quote={quote} quoteData={quoteData}/>
              </View>

          <View style={styles.customerSignature}>
            <Text style={styles.signatureText}>Customer Signature</Text>
            <View style={styles.signatureLine}></View>
            <Text style={styles.signatureText}>Name</Text>
            <View style={styles.signatureLine}></View>
            <Text style={styles.signatureText}>Date</Text>
            <View style={styles.signatureLine}></View>
          </View>


          <View style={styles.consultantSignature}>
            <Text style={styles.signatureText}>Consultant Signature</Text>
            <View style={styles.signatureLine}></View>
            <Text style={styles.signatureText}>Name</Text>
            <View style={styles.signatureLine}></View>
            <Text style={styles.signatureText}>Date</Text>
            <View style={styles.signatureLine}></View>
          </View>
          
          <View style={styles.footer}>
            <Text> &copy; 2019 Tuff Shed Inc. | Page 1 of 1</Text>
          </View>
      </Page>
    </Document>
      
  


      

  )
};

export default QuotePDF

