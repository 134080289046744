import React from 'react'
import { Grid, Box, Typography, TextField } from '@material-ui/core';


const WallInfo = ({ quote, handleQuoteChange }) => {
    const { size, region } = quote


    return (

        <Box p={3}>
            <Typography variant="h6" gutterBottom component="h2">Select Window and Door Locations</Typography>
            <Grid container spacing={3}>
            {
                    (size === "" || region === "") ? 
                        (   
                            <Grid item>
                                <Typography
                                    variant="h6" 
                                    gutterBottom 
                                    component="h3" 
                                    color="secondary"
                                >
                                    *Select Region and Size* 
                                </Typography>

                            </Grid>
                        ) 

                        :

                        (   
                            <Grid container >
                                <Grid item xs={12}> 
                                    <img src="/img/garagePlanView.jpg" alt="Plan View of A Garage"  />
                                </Grid>
                                <Grid item xs={12}> 
                                <Box px={1} mt={1}>
                                    <Typography 
                                        variant="body1" 
                                        gutterBottom 
                                        component="h3" 
                                    >
                                        Describe window and door placement, by wall below. Maximum 2 items per wall.  
                                        Door and window total should match number selected above.
                                        For example:  Wall B – two windows, Wall C – no windows, Wall D – one window and one door
                                    </Typography>
                                </Box>
                                </Grid>
                                
                                <Grid item xs={12}> 
                                    <TextField
                                        fullWidth
                                        id="wallInfo"
                                        label="Describe Window and Door Placement"
                                        multiline
                                        rows="4"
                                        value={quote.wallInfo}
                                        // className={classes.textField}
                                        margin="normal"
                                        variant="filled"
                                        name="wallInfo"
                                        onChange={handleQuoteChange}
                                    />
                                </Grid>

                
                            </Grid>

                        ) 
                }         
            

            </Grid>
        </Box>
    )
}

export default WallInfo

