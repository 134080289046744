import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {MODEL_UPGRADES_TEXT} from '../theme/shedData'


const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})

const markup = string => dollarize(Math.abs(string)/ .85)

const dollarize = string => {
  if (parseFloat(string)){
      return formatter.format(string)
  }else{
      return string 
  }
}



const QuoteTable = ({ quote }) => {

  const initialRows = [

    {item: quote.modelName, quantity: quote.qty , price: `${dollarize(quote.claytonPrice / .85)}` || 0},
    {item: 'Windows', quantity: (quote.modelName === "**Select Garage Model**" ? "-" : quote.windowCount ), price: `${dollarize(quote.windowsPrice / .85)}` || 0},
    {item: `Siding Color - ${quote.sidingColor}`, quantity: "-", price: "0"},
    {item: `Trim Color - ${quote.trimColor}`, quantity: "-", price: "0"},
    {item: `Shingle Color - ${quote.shingleColor}`, quantity: "-", price: "0"},
    {item: 'Garage Total', quantity:"", price: `${dollarize( ((quote.customerTotal / .85) + (quote.windowsPrice / .85)+ (quote.shuttersPrice / .85)) ) }` || 0},
    {item: 'Concrete Pad', quantity: "", price: `${dollarize(quote.slabAllowance)}`|| 0},
    { item: 'Electrical', quantity: "", price: `${dollarize(quote.electrical)}` || 0 },

    {item: 'Grand Total', quantity:"", price: `${
          dollarize(
            (
                (quote.customerTotal / .85) +
                parseFloat(quote.slabAllowance || 0) +
                parseFloat(quote.electrical || 0) +
                (quote.windowsPrice / .85) +
                (quote.shuttersPrice / .85)
            )
          )
        }` 
        || 0
  }
  ];


  const matchText = string => {
    const upgradesAsList = Object.entries(MODEL_UPGRADES_TEXT)
    const matchItemDescription = upgradesAsList.filter(item => (item[0] === string))
    const itemDescription = matchItemDescription[0][1]
    return itemDescription
  }

  const checkQuantity = upgrade => {
    if (upgrade[0] === 'shutters') {
      return parseInt(quote.windowCount)
    } else if (upgrade[0] === 'insulatedWindow'){
      return (parseInt(quote.windowCount))
    } else {
      return 1
    }
  }

// 


  const checkPrice = upgrade => {
    if (upgrade[0] === 'shutters') {
      return `${markup(upgrade[1] * quote.windowCount)}`
    } else {
      return `${markup(Math.abs(upgrade[1]))}`
    }
  }

  const getRows = () => {
    let rows = initialRows
    
    const quoteAsList = Object.entries(quote)
    const addedUpgrades = quoteAsList.filter(item => (typeof (item[1]) === "number" && item[1] < 0 ))
    if (addedUpgrades.length > 0){
      addedUpgrades.map(upgrade => {
          let formattedUpgrade =
            {item: `${matchText(upgrade[0])}`, quantity: checkQuantity(upgrade), price: checkPrice(upgrade)} 
            rows.splice(-4, 0, formattedUpgrade)   
      })  
    } 
    

    return rows.map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          {row.item}
        </TableCell>
        <TableCell align="right">{row.quantity}</TableCell>
        <TableCell align="right">{row.price}</TableCell>
      </TableRow>
    ))

  }




  return (
    <Paper>
      <Table aria-label="quote table">
        <TableHead>
          <TableRow>
            <TableCell>Item</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {getRows()}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default QuoteTable

