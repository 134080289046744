export const DIMENSIONAL_SHINGLE_COLORS = [
    "Birchwood",
    "Weathered Wood",
    "Shakewood",
    "TBD",
];
export const SHINGLE_COLORS = [
    "Hickory",
    "Charcoal",
    "Weathered Wood",
    "Shakewood",
    "TBD",
];

export const SIDING_COLORS = [
    "Delicate White",
    "Tundra Frost",
    "Cherokee Red",
    "Brick Dust ",
    "Hunt Club Red",
    "Warm Up",
    "Cool Clay",
    "Bulletin Board",
    "Waves of Grain",
    "Southern Breeze",
    "Smokey Slate",
    "Autumn Gray",
    "Licorice",
    "Night Watch",
    "Seastone",
    "Ground Coffee",
    "Sheffield Gray",
    "Goblin",
    "Notorious",
    "Cocoa Cream",
    "Hat Box Brown",
    "Almost Brittle",
    "Maison De Campagne",
    "Gray By Me",
    "Ghost Writer",
    "Solitary Slate",
    "Dover Gray",
    "Knight’s Armor",
    "Black Magic",
    "Pumpkin Patch",
    "TBD",
];

export const TRIM_COLORS = [
    "Delicate White",
    "Tundra Frost",
    "Cherokee Red",
    "Brick Dust ",
    "Hunt Club Red",
    "Warm Up",
    "Cool Clay",
    "Bulletin Board",
    "Waves of Grain",
    "Southern Breeze",
    "Smokey Slate",
    "Autumn Gray",
    "Licorice",
    "Night Watch",
    "Seastone",
    "Ground Coffee",
    "Sheffield Gray",
    "Goblin",
    "Notorious",
    "Cocoa Cream",
    "Hat Box Brown",
    "Almost Brittle",
    "Maison De Campagne",
    "Gray By Me",
    "Ghost Writer",
    "Solitary Slate Dover Gray",
    "Knight’s Armor",
    "Black Magic",
    "Pumpkin Patch",
    "TBD",
];

export const MODEL_FEATURES_TEXT = {
    shedSize: "Shed Dimensions",
    basePrice: "Base Price",
    engineeringFees: "Engineering Fees",
    delivery: "Delivery",
    subtotal: "Subtotal",
    discount: "Discount",
    claytonPrice: "Clayton Price",
    slabAllowance: "Concrete Pad Allowance",
    garageWithPad: "Garage with pad",
    customerTotal: "Clayton Customer (Total with 15% markup)",
    wallBarrier: "SilverTech Radiant Barrier for Walls Deducted",
    // highWind:"**Dimensional Shingles (High Wind Requirement)**",

    garageDoor: `16'x7' Standard Garage Door`,
    door: "6-Panel Residential Door",
    roofDecking: "Radiant Barrier Roof Decking",
    houseWrap: "Housewrap Vapor Barrier for Walls Added",
    gableOverhang: `12" Endwall Eaves (Gable Overhang Upgrade)`,
    paintUpgrade: "Two Color Paint Upgrade",
    ridgeVent: "Ridge Vent",
    windows: `Two 3'x2' Insulated Horizontal Windows`,
};

// dimensionalShinglesFiveTwelve:"Dimensional Shingles (With 5/12 Roof)",
// dimensionalShinglesSixTwelve:"Dimensional Shingles (With 6/12 Roof)",

export const MODEL_UPGRADES_TEXT = {
    extraDoor: "Additional 6-Panel Residential Door",
    insulatedWindow: "Additional 3x2 Insulated Horizontal Windows",
    shutters: "Shutters (Pairs Per Window)",
    gableVents: `12"x12" Gable End Vents (Pair)`,
    blackHardware: "Garage Door Upgrade - Black Hardware ",
    insulatedGarageDoor: "Garage Door Upgrade - Insulated Door",
    garageDoorWindow: "Garage Door Upgrade - Glass Top Panel",
    highWind: "High Wind Upgrade",
    highSnow: "High Snowfall Zone Upgrade",
    dimensionalShingles:
        "Dimensional Shingles *High Wind Zones Require Dimensional Shingles*",
    fiveTwelveRoof: "Roof Pitch Upgrade 5/12",
    sixTwelveRoof: "Roof Pitch Upgrade 6/12",
    atticTruss: "Attic Truss w/loft sheeting - *Roof Pitch Upgrade Required*",
    scissorTruss: "Scissor Truss - *Roof Pitch Upgrade Required*",
    generatorRental:
        "Generator Rental *Necessary If Site is Over 100' From Outlet*",
};

export const INITIAL_STATE = {
    requestNumber: "",
    orderForm: true,
    repFirstName: "",
    repLastName: "",
    repEmail: "",
    repPhone: "",
    homeCenterNumber: "",
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
    slabAllowance: "",
    electrical: "",
    claytonPo: "",
    claytonOrderNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    date: new Date().toISOString().slice(0, 10),
    installDate: "",
    notes: "",
    region: "",
    size: "",
    modelName: "**Select Garage Model**",
    claytonDiscountPercentage: 11,
    upgradesTotal: 0,
    customerTotal: 0,
    garageWithPad: 0,
    subtotal: 0,
    discount: 0,
    tsSubtotal: 0,
    basePrice: 0,
    claytonPrice: 0,
    customerPrice: 0,
    roofPitch: 4,
    windowCount: 2,
    windowsPrice: 0,
    shuttersCount: 2,
    shuttersPrice: 0,
    qty: "",
    wallInfo: "",
    highWindOption: false,
    shingleColor: "TBD",
    sidingColor: "TBD",
    trimColor: "TBD",
    highWind: 0,
};

export const INITIAL_QUOTE_TEXT = {
    claytonPo: "Clayton PO Number:",
    repId: "Representitive ID:",
    storeLocation: "Store Location:",
    firstName: "First Name:",
    lastName: "Last Name:",
    address1: "Address Line 1:",
    address2: "Address Line 2:",
    city: "City:",
    state: "State:",
    zip: "Zip:",
    country: "Country:",
    date: "Date:",
    notes: "Notes:",
    region: "Region:",
    size: "Size:",
    modelName: "Model Name:",
    basePrice: "Base Price:",
    upgradesTotal: "Upgrades SubTotal",
    slabAllowance: "Concrete Pad Allowance",
    garageWithPad: "Garage with pad",
    customerTotal: "Clayton Customer Total",
};

export const SHED_DATA = [
    //Tier A
    {
        modelName: `20' X 20' Garage Tier-A`,
        size: "20",
        tier: "A",
        region: "A",

        qty: "1",        
        claytonPrice: "16245.15",

        highSnow: "2281.50",
        highWind: "860",
        dimensionalShingles: "836.55",
        extraDoor: "575",
        fiveTwelveRoof: "911.75",
        sixTwelveRoof: "1876",
        atticTruss: "2412",
        scissorTruss: "1876",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
    {
        modelName: `22' X 22' Garage Tier-A`,
        size: "22",
        tier: "A",
        region: "A",

        qty: "1",
        claytonPrice: "18603.75",

        highSnow: "2718",
        highWind: "1040.60",
        dimensionalShingles: "996.60",
        extraDoor: "575",
        fiveTwelveRoof: "1085",
        sixTwelveRoof: "2236.50",
        atticTruss: "2875.5",
        scissorTruss: "2236.50",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
    {
        modelName: `24' X 24' Garage Tier-A`,
        size: "24",
        tier: "A",
        region: "A",

        qty: "1",
        claytonPrice: "20368.65",

        highSnow: "3190.5",
        highWind: "1238.40",
        dimensionalShingles: "1169.85",
        extraDoor: "575",
        fiveTwelveRoof: "1274",
        sixTwelveRoof: "2625",
        atticTruss: "3375",
        scissorTruss: "2625",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
    //Tier B
    {
        modelName: `20' X 20' Garage Tier-B`,
        size: "20",
        tier: "B",
        region: "B",

        qty: "1",
        claytonPrice: "16648.85",

        highSnow: "2281.50",
        highWind: "860",
        dimensionalShingles: "836.55",
        extraDoor: "575",
        fiveTwelveRoof: "911.75",
        sixTwelveRoof: "1876",
        atticTruss: "2412",
        scissorTruss: "1876",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
    {
        modelName: `22' X 22' Garage Tier-B`,
        size: "22",
        tier: "B",
        region: "B",

        qty: "1",
        claytonPrice: "19071.25",

        highSnow: "2718",
        highWind: "1040.60",
        dimensionalShingles: "996.60",
        extraDoor: "575",
        fiveTwelveRoof: "1085",
        sixTwelveRoof: "2236.50",
        atticTruss: "2875.5",
        scissorTruss: "2236.50",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
    {
        modelName: `24' X 24' Garage Tier-B`,
        size: "24",
        tier: "B",
        region: "B",

        qty: "1",
        claytonPrice: "20880.15",

        highSnow: "3190.5",
        highWind: "1238.40",
        dimensionalShingles: "1169.85",
        extraDoor: "575",
        fiveTwelveRoof: "1274",
        sixTwelveRoof: "2625",
        atticTruss: "3375",
        scissorTruss: "2625",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
    //Tier C
    {
        modelName: `20' X 20' Garage Tier-C`,
        size: "20",
        tier: "C",
        region: "C",

        qty: "1",
        claytonPrice: "17052.55",

        highSnow: "2281.50",
        highWind: "860",
        dimensionalShingles: "836.55",
        extraDoor: "575",
        fiveTwelveRoof: "911.75",
        sixTwelveRoof: "1876",
        atticTruss: "2412",
        scissorTruss: "1876",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
    {
        modelName: `22' X 22' Garage Tier-C`,
        size: "22",
        tier: "C",
        region: "C",

        qty: "1",
        claytonPrice: "19537.65",

        highSnow: "2718",
        highWind: "1040.60",
        dimensionalShingles: "996.60",
        extraDoor: "575",
        fiveTwelveRoof: "1085",
        sixTwelveRoof: "2236.50",
        atticTruss: "2875.5",
        scissorTruss: "2236.50",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
    {
        modelName: `24' X 24' Garage Tier-C`,
        size: "24",
        tier: "C",
        region: "C",

        qty: "1",
        claytonPrice: "21392.75",

        highSnow: "3190.5",
        highWind: "1238.40",
        dimensionalShingles: "1169.85",
        extraDoor: "575",
        fiveTwelveRoof: "1274",
        sixTwelveRoof: "2625",
        atticTruss: "3375",
        scissorTruss: "2625",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
    //Tier D
    {
        modelName: `20' X 20' Garage Tier-D`,
        size: "20",
        tier: "D",
        region: "D",

        qty: "1",
        claytonPrice: "17456.25",

        highSnow: "2281.50",
        highWind: "860",
        dimensionalShingles: "836.55",
        extraDoor: "575",
        fiveTwelveRoof: "911.75",
        sixTwelveRoof: "1876",
        atticTruss: "2412",
        scissorTruss: "1876",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
    {
        modelName: `22' X 22' Garage Tier-D`,
        size: "22",
        tier: "D",
        region: "D",

        qty: "1",
        claytonPrice: "20002.95",

        highSnow: "2718",
        highWind: "1040.60",
        dimensionalShingles: "996.60",
        extraDoor: "575",
        fiveTwelveRoof: "1085",
        sixTwelveRoof: "2236.50",
        atticTruss: "2875.5",
        scissorTruss: "2236.50",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
    {
        modelName: `24' X 24' Garage Tier-D`,
        size: "24",
        tier: "D",
        region: "D",

        qty: "1",
        claytonPrice: "21904.25",

        highSnow: "3190.5",
        highWind: "1238.40",
        dimensionalShingles: "1169.85",
        extraDoor: "575",
        fiveTwelveRoof: "1274",
        sixTwelveRoof: "2625",
        atticTruss: "3375",
        scissorTruss: "2625",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
    //Tier E
    {
        modelName: `20' X 20' Garage Tier - E`,
        size: "20",
        tier: "E",
        region: "E",

        qty: "1",
        claytonPrice: "17859.95",

        highSnow: "2281.50",
        highWind: "860",
        dimensionalShingles: "836.55",
        extraDoor: "575",
        fiveTwelveRoof: "911.75",
        sixTwelveRoof: "1876",
        atticTruss: "2412",
        scissorTruss: "1876",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
    {
        modelName: `22' X 22' Garage Tier - E`,
        size: "22",
        tier: "E",
        region: "E",

        qty: "1",
        claytonPrice: "20469.35",

        highSnow: "2718",
        highWind: "1040.60",
        dimensionalShingles: "996.60",
        extraDoor: "575",
        fiveTwelveRoof: "1085",
        sixTwelveRoof: "2236.50",
        atticTruss: "2875.5",
        scissorTruss: "2236.50",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
    {
        modelName: `24' X 24' Garage Tier - E`,
        size: "24",
        tier: "E",
        region: "E",

        qty: "1",
        claytonPrice: "22415.75",

        highSnow: "3190.5",
        highWind: "1238.40",
        dimensionalShingles: "1169.85",
        extraDoor: "575",
        fiveTwelveRoof: "1274",
        sixTwelveRoof: "2625",
        atticTruss: "3375",
        scissorTruss: "2625",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
    //Tier G
    {
        modelName: `20' X 20' Garage Tier - G`,
        size: "20",
        tier: "G",
        region: "G",

        qty: "1",
        claytonPrice: "18667.35",

        highSnow: "2281.50",
        highWind: "860",
        dimensionalShingles: "836.55",
        extraDoor: "575",
        fiveTwelveRoof: "911.75",
        sixTwelveRoof: "1876",
        atticTruss: "2412",
        scissorTruss: "1876",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
    {
        modelName: `22' X 22' Garage Tier - G`,
        size: "22",
        tier: "G",
        region: "G",

        qty: "1",
        claytonPrice: "21402.15",

        highSnow: "2718",
        highWind: "1040.60",
        dimensionalShingles: "996.60",
        extraDoor: "575",
        fiveTwelveRoof: "1085",
        sixTwelveRoof: "2236.50",
        atticTruss: "2875.5",
        scissorTruss: "2875.5",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
    {
        modelName: `24' X 24' Garage Tier - G`,
        size: "24",
        tier: "G",
        region: "G",

        qty: "1",
        claytonPrice: "23439.85",

        highSnow: "3190.5",
        highWind: "1238.40",
        dimensionalShingles: "1169.85",
        extraDoor: "575",
        fiveTwelveRoof: "1274",
        sixTwelveRoof: "2625",
        atticTruss: "3375",
        scissorTruss: "2625",
        insulatedWindow: "229",
        gableVents: "74",
        shutters: "55",
        blackHardware: "195",
        insulatedGarageDoor: "700",
        garageDoorWindow: "700",
        generatorRental: "150",
    },
];
