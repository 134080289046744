import React from 'react';
import { Grid, Box, Typography, FormControl, InputLabel, Select } from '@material-ui/core';
import { DIMENSIONAL_SHINGLE_COLORS, SHINGLE_COLORS, SIDING_COLORS, TRIM_COLORS } from './shedData'



const ColorSelector = ({quote, handleQuoteChange }) => {
    const { size, region } = quote
    let shingleType = (parseFloat(quote.dimensionalShingles) < 0 ? DIMENSIONAL_SHINGLE_COLORS : SHINGLE_COLORS)

    return (

        <Box p={3}>
            <Typography variant="h6" gutterBottom>Select Paint and Shingle Color</Typography>
            
                {
                    (size === "" || region === "") ? 
                        (   
                            <Grid item>
                                <Typography
                                    variant="h6" 
                                    gutterBottom 
                                    component="h3" 
                                    color="secondary"
                                >
                                    *Select Region and Size*
                                </Typography>

                            </Grid>
                        ) 

                        :

                        (   
                            <Grid container spacing={3}>

                                <Grid item xs={4}> 
                                <FormControl style={{minWidth: 100}}>
                                    <InputLabel htmlFor="sidingColor">Siding Paint Color</InputLabel>
                                        <Select
                                        native
                                        name='sidingColor'
                                        value={quote.sidingColor}
                                        onChange={handleQuoteChange}
                                        inputProps={{
                                            name: 'sidingColor',
                                            id: 'sidingColor',
                                        }}
                                        >
                                            {
                                                SIDING_COLORS.map((color, index) => {
                                                return  ( <option key={index} value={color}> {color} </option>)
                                                })
                                            }


                                        </Select>
                                </FormControl>
                                </Grid>

                                <Grid item xs={4}> 
                                <FormControl style={{minWidth: 100}}>
                                    <InputLabel htmlFor="trimColor">Trim Paint Color</InputLabel>
                                        <Select
                                        native
                                        name='trimColor'
                                        value={quote.trimColor}
                                        onChange={handleQuoteChange}
                                        inputProps={{
                                            name: 'trimColor',
                                            id: 'trimColor',
                                        }}
                                        >
                                            {
                                                TRIM_COLORS.map((color, index) => {
                                                return  ( <option key={index} value={color}> {color} </option>)
                                                })
                                            }

                                        </Select>
                                </FormControl>
                                </Grid>
                                
                                <Grid item xs={4}> 
                                <FormControl style={{minWidth: 100}}>
                                    <InputLabel htmlFor="shingleColor">Shingle Color</InputLabel>
                                        <Select
                                        native
                                        name='shingleColor'
                                        value={quote.shingleColor}
                                        onChange={handleQuoteChange}
                                        inputProps={{
                                            name: 'shingleColor',
                                            id: 'shingleColor',
                                        }}
                                        >
                                            {
                                                (shingleType).map((color, index) => {
                                                return  ( <option key={index} value={color}> {color} </option>)
                                                })
                                            }

                                        </Select>
                                </FormControl>
                                </Grid>                  




                            </Grid>    
                        ) 
                }         
        </Box>
    )
   

}        

export default ColorSelector


