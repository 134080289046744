import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default function SendOrderModal({handleSendOrderModal, form, quote, sendEmail, handleEmailResponse, handleClickSubmit, errors}) {
  const modalText = () => {
    if ((!form.emailResponse && !form.orderFormResponse) && (!form.clickSubmit) && (errors.length === 0)){
      return "Submit Order Form To Tuff Shed?"
    }
    else if ((!form.emailResponse && !form.orderFormResponse) && (form.clickSubmit) && (errors.length === 0)){
      return "Sending Order Form"
    }
    else if ((!form.emailResponse && !form.orderFormResponse) && (form.clickSubmit) && (errors.length === 2)){
      return "Sending Order Form"
    }
    
    else if ((!form.emailResponse || !form.orderFormResponse) && (form.clickSubmit) && (errors.length === 1)){
      return "Sending Order Form"
    } 
    
    else if ((form.emailResponse && form.orderFormResponse)){
      return( 
        `Your order has been successfully received! 
        A copy has been sent to ${quote.repEmail}`)
    } 

    else if ((!form.emailResponse || !form.orderFormResponse)  && (errors.length !== 0)){
      return "There has been an error, please check required fields and re-submit the order form."
    } 

    else {
      return "Sending Order Form"

    }

    
  }


  const checkSubmitButton = () =>{
    if (quote.repEmail.length < 4 ) {
      return true
    } else if (quote.lastName === ""){
      return true
    } else if (form.emailResponse && form.orderFormResponse){
      return true
    }  else if (form.clickSubmit && errors.length === 0){
        return true
    } else if ((!form.emailResponse && !form.orderFormResponse) && (form.clickSubmit) && (errors.length === 0)){
        return true    
    } else {
      return false
    }
  }

  return (
    <div>

      <Dialog
        open={form.sendOrderModal}
        onClose={handleSendOrderModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Submit Order"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* {!form.orderFormResponse && form.emailResponse ? "Submit Order Form To Tuff Shed?" : modalText()}   */}
            {modalText()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendOrderModal} color="secondary">
            {form.orderFormResponse ? "Close" : "Cancel"}  

          </Button>
          <Button 
            onClick={handleClickSubmit}
            disabled={checkSubmitButton()} 
            color="primary"

            autoFocus
          >
           Submit

          </Button>
        </DialogActions>
      </Dialog>
    </div>
    )

            
}