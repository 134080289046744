import React from 'react'
import { MODEL_FEATURES_TEXT } from './shedData'
import { Grid, Box,Typography } from '@material-ui/core';




const ModelFeatures = ({ quote, handleQuoteChange }) => {

    // const numberOfUpgrades = (quote.region === "E" ? 11 : 12)
    const options =  (Object.entries(MODEL_FEATURES_TEXT).slice(11))



    const { size, region } = quote

    return (

        <Box p={3}>
            <Typography variant="h6" gutterBottom component="h2">Model Features</Typography>
            <Grid container spacing={3}>
            
                {
                    (size === "" || region === "") ? 
                        (   
                            <Grid item>
                                <Typography
                                    variant="h6" 
                                    gutterBottom 
                                    component="h3" 
                                    color="secondary"
                                >
                                *Select Region and Size*
                                </Typography>
                            </Grid>
                        ) 

                        :

                        (   
                            <Grid item xs={12}> 
                                {   
                                    options.map((option, index) => {
                                        let text = option[1]
                                        return (
                                            <Typography key={index}>
                                                {text}
                                            </Typography>
                                        )
                                    })
                                }
                            </Grid>


                        ) 
                }         
            

            </Grid>
        </Box>
    )
}

export default ModelFeatures

