import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';

export default function ClaytonLogin(props) {
    const [claytonId, setClaytonId] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        setErrorMessage(null);

        // Minimum wait for animation since we're not using any real auth calls for Clayton yet
        setTimeout(() => {            
            if (password === 'TuffShed2019' || password === 'TuffShed2020' || password === 'TuffShed2021') { // Static hardcoded password for basic configurator
                props.onSuccess({ claytonId }); // Auth success callback
            }
            else {
                setErrorMessage('Incorrect Password. Please try again.')
            }
            setLoading(false);
        }, 500);
    }

    const classes = makeStyles(theme => ({
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
          },
    }))();

    return <Container component="main" maxWidth="xs">
        <Box mt={5}>
            <Paper>
                {errorMessage && <Box px={5} py={3} color='white' bgcolor='error.main'>{errorMessage}</Box>}
                <Box p={5}>
                    <Typography component="h1" variant="h5">Log in</Typography>
                    <p>Welcome to the Tuff Shed Partner Portal for Clayton Homes.</p>
                    <form onSubmit={handleSubmit}>
                        <TextField margin="normal" required fullWidth id="claytonId" label="Clayton Rep ID" name="claytonId" autoFocus onChange={(e) => setClaytonId(e.target.value)} />
                        <TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" onChange={(e) => setPassword(e.target.value)} />
                        <Box mt={3} position='relative'>
                            <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading} >Sign In</Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Box>
                    </form>
                </Box>
            </Paper>
        </Box>
    </Container>;
}
