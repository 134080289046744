import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import QuotePDF from './QuotePDF'
import { PDFDownloadLink } from '@react-pdf/renderer'


export default function SaveQuoteModal({handleGenerateQuote, form, quote}) {

  return (
    <div>

      <Dialog
        open={form.generateQuote || false}
        onClose={handleGenerateQuote}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Save PDF"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Save a PDF of the Configured Quote? 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
          onClick={handleGenerateQuote} 
          color="secondary">
            Cancel
          </Button>
          <Button onClick={handleGenerateQuote} color="primary" autoFocus>
           
              <PDFDownloadLink 
                  document={(form.generateQuote ? (<QuotePDF quote={quote}  form={form}/>) : null )} 
                  fileName={`TS-Clayton ${quote.lastName} ${quote.requestNumber}`} 
                  style={{ textDecoration: 'none', color: 'primary' }}
              >
                  {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Save PDF')} 

              </PDFDownloadLink>

          </Button>
        </DialogActions>
      </Dialog>
    </div>
    )

            
}