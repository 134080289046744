import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export default function HDHome(props) {
    return <Container maxWidth="lg">
        <Typography variant="body1" align="center" style={{marginTop:30}} gutterBottom>Please visit Service Connect for Home Depot sales.</Typography>
        <Box textAlign="center">
        <Button variant="contained" color="primary" href="https://www.888tuffshed.com/service-connect">
            Service Connect
        </Button>
        </Box>
    </Container>;
}