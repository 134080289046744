import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Route, Switch } from "react-router-dom";
import HDNav from './HDNav';
import HDHome from './pages/HDHome';
import { HDMuiTheme } from './theme/HDMuiTheme';

export default function HDPortal(props) {
    let componentRoot = props.match.path.replace(/\/$/, "");

    return <div id="HDPortal">
        <ThemeProvider theme={HDMuiTheme}>
            <HDNav match={props.match} />
            <Switch>                
                <Route exact path={`${componentRoot}/`} component={HDHome} />
            </Switch>
        </ThemeProvider>
    </div>
}