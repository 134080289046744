import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import ClaytonLogin from './ClaytonLogin';
import ClaytonNav from './ClaytonNav';
import ClaytonHome from './pages/ClaytonHome';
import { ClaytonMuiTheme } from './theme/ClaytonMuiTheme';
import ClaytonConfigurator from './pages/ClaytonConfigurator';

export default function ClaytonPortal(props) {
    let componentRoot = props.match.path.replace(/\/$/, "");

    const [claytonUser, setClaytonUser] = React.useState(() => {
        var sessionClaytonUser = JSON.parse(sessionStorage.getItem('claytonUser'));
        console.log('Clayton User from session: ', sessionClaytonUser);
        return sessionClaytonUser;
    });

    const logout = () => {
        setClaytonUser(null);
        sessionStorage.removeItem('claytonUser');
    }

    const updateUser = (user) => {
        console.log('Updating Clayton User', user);
        setClaytonUser(user);
        sessionStorage.setItem('claytonUser', JSON.stringify(user));
    }

    const onAuthSuccess = (user) => {        
        updateUser(user);
    }

    const ClaytonRoute = ({ render, ...rest }) => (
        <Route {...rest} render={(props) => (
            claytonUser
                ? render(props)
                : <Redirect to={`${componentRoot}/login`} />
        )} />
    );

    return <div id="ClaytonPortal">
        <ThemeProvider theme={ClaytonMuiTheme}>
            <ClaytonNav user={claytonUser} match={props.match} onLogout={logout} />
            <Switch>
                <Route
                    path={`${componentRoot}/login`}
                    render={(rp) => claytonUser
                        ? <Redirect to={`${componentRoot}`} />
                        : <ClaytonLogin {...rp}  onSuccess={onAuthSuccess}/> } />
                <ClaytonRoute
                    exact path={`${componentRoot}/`}
                    render={(rp) => <ClaytonHome {...rp} />} />
                <ClaytonRoute
                    exact path={`${componentRoot}/configurator`}
                    render={(rp) => <ClaytonConfigurator  {...rp} />} />
                {/* 404 Route: */}
                <ClaytonRoute
                    path={`${componentRoot}/*`}
                    render={(rp) => <div>404</div>} />
            </Switch>
        </ThemeProvider>
    </div>;
}