import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1
    },
    appBar: {
        backgroundColor: "#fff"
    },
    logo: {
        height: 64,
        padding: 14
    },
    logoImg: {
        maxHeight: "100%",
        maxWeight: "100%",
        display: 'inline-block'
    },
    logoDiv: {
        width: 1,
        height: "100%",
        borderRight: 1,
        borderRightColor: theme.palette.grey[400],
        borderRightStyle: 'solid',
        display: 'inline-block',
        margin: '0 10px'
    }
}));

export default function ClaytonNav(props) {
    let componentRoot = props.match.path.replace(/\/$/, "");
    const classes = useStyles();

    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                <Link to={`${componentRoot}/`} className={classes.logo}>
                    <img src="/logo-hor.png" alt="Tuff Shed" className={classes.logoImg} />
                    <div className={classes.logoDiv} />
                    <img src="/img/clayton-logo.png" alt="Clayton Home" className={classes.logoImg} />
                </Link>
                <div className={classes.grow} />
                {props.user && <Button color="primary" onClick={props.onLogout}>Logout</Button>}
            </Toolbar>
        </AppBar>
    );
}