import React from "react";
import { Box, Typography, TextField } from "@material-ui/core";
import RegionSelect from "./RegionSelect";
import SizeSelect from "./SizeSelect";

const TierInfo = ({ quote, handleQuoteChange }) => {
    return (
        <Box p={3}>
            <Typography variant="h6" gutterBottom component="h2">
                Pricing Tier (Region and Size)
            </Typography>
            <Box display="flex" ml={1}>
                <Box mr={2}>
                    {" "}
                    <RegionSelect
                        quote={quote}
                        handleQuoteChange={handleQuoteChange}
                    />{" "}
                </Box>
                <Box mr={2}>
                    {" "}
                    <SizeSelect
                        quote={quote}
                        handleQuoteChange={handleQuoteChange}
                    />{" "}
                </Box>
                <Box width={1 / 2} mr={2}>
                    <TextField
                        // required
                        id="slabAllowance"
                        name="slabAllowance"
                        label="Concrete Pad (Estimated Price)"
                        fullWidth
                        autoComplete=""
                        onChange={handleQuoteChange}
                        value={quote.slabAllowance}
                    />
                </Box>
                <Box width={1 / 2}>
                    <TextField
                        // required
                        id="electrical"
                        name="electrical"
                        label="Electrical (Estimated Price)"
                        fullWidth
                        autoComplete=""
                        onChange={handleQuoteChange}
                        value={quote.electrical}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default TierInfo;
